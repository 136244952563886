const json = {
    panels: [
        {
            index: 0,
            name: 1,
            height: 30,
            width: 60,
            paperHeader: 49.5,
            paperFooter: 78.78787878787878,
            printElements: [
                {
                    options: {
                        left: 36,
                        top: 0,
                        height: 9.75,
                        width: 120,
                        testData: "测试设备",
                        field: "deviceName",
                        coordinateSync: false,
                        widthHeightSync: false,
                        title: "名称",
                        qrCodeLevel: 0
                    },
                    printElementType: {
                        title: "这是更新后的元素",
                        type: "text"
                    }
                },
                {
                    options: {
                        left: 36,
                        top: 15,
                        height: 9.75,
                        width: 120,
                        testData: 'SK-1-45',
                        field: "sampleModel",
                        coordinateSync: false,
                        widthHeightSync: false,
                        title: "型号",
                        qrCodeLevel: 0
                    },
                    printElementType: {
                        title: "这是更新后的元素",
                        type: "text"
                    }
                },
                {
                    options: {
                        left: 5,
                        top: 30,
                        height: 40,
                        width: 160,
                        testData: "ZC2024EETC01004",
                        hCenter: 50,
                        bottom: 70,
                        barcodeType: "code128",
                        coordinateSync: false,
                        title: "条形码",
                        hideTitle: true,
                        widthHeightSync: false,
                        barAutoWidth: true
                    },
                    printElementType: {
                        title: "条形码",
                        type: "barcode"
                    }
                }
            ],
            paperNumberLeft: 140,
            paperNumberTop: -13,
            paperNumberContinue: true,
            watermarkOptions: {},
            panelLayoutOptions: {}
        }
    ]
};
const deviceJson = JSON.stringify(json);
export default deviceJson;
