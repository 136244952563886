// 深拷贝
const deepClone = data => {
    if (typeof data !== "object" || data == null) {
        return data
    }
    let res
    if (Array.isArray(data)) {
        res = []
    } else {
        res = {}
    }
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            res[key] = deepClone(data[key])
        }
    }
    return res
}

const getChineseNum = num => {
    let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"] //changeNum[0] = "零"
    let unit = ["", "十", "百", "千", "万"]
    num = parseInt(num)
    let getWan = temp => {
        let strArr = temp.toString().split("").reverse()
        let newNum = ""
        for (var i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? "" : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) + newNum
        }
        return newNum
    }
    let overWan = Math.floor(num / 10000)
    let noWan = num % 10000
    if (noWan.toString().length < 4) noWan = "0" + noWan
    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num)
}

/**
 * @description 生成uuid
 * @param {*} len
 * @param {*} radix
 * @returns uuid
 */
const getUuid = (len, radix) => {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    let uuid = [],
        i
    radix = radix || chars.length
    if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
    } else {
        var r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-"
        uuid[14] = "4"
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16)
                uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
            }
        }
    }
    return uuid.join("")
}

function toFixed(number, len) {
    if (len > 20 || len < 0) {
        throw new RangeError("toFixed() digits argument must be between 0 and 20")
    }
    // .123转为0.123
    // var number = Number(this);
    number = Number(number);
    if (isNaN(number) || number >= Math.pow(10, 21)) {
        return number.toString()
    }
    if (typeof len == "undefined" || len == 0) {
        return Math.round(number).toString()
    }
    var result = number.toString(),
        numberArr = result.split(".")

    if (numberArr.length < 2) {
        //整数的情况
        return padNum(result)
    }
    var intNum = numberArr[0], //整数部分
        deciNum = numberArr[1], //小数部分
        lastNum = deciNum.substr(len, 1) //最后一个数字

    if (deciNum.length == len) {
        //需要截取的长度等于当前长度
        return result
    }
    if (deciNum.length < len) {
        //需要截取的长度大于当前长度 1.3.toFixed(2)
        return padNum(result)
    }
    //需要截取的长度小于当前长度，需要判断最后一位数字
    result = intNum + "." + deciNum.substr(0, len)
    if (parseInt(lastNum, 10) >= 5) {
        //最后一位数字大于5，要进位
        var times = Math.pow(10, len) //需要放大的倍数
        var changedInt = Number(result.replace(".", "")) //截取后转为整数
        changedInt++ //整数进位
        changedInt /= times //整数转为小数，注：有可能还是整数
        result = padNum(changedInt + "")
    }
    return result
    //对数字末尾加0
    function padNum(num) {
        var dotPos = num.indexOf(".")
        if (dotPos === -1) {
            //整数的情况
            num += "."
            for (var i = 0; i < len; i++) {
                num += "0"
            }
            return num
        } else {
            //小数的情况
            var need = len - (num.length - dotPos - 1)
            for (var j = 0; j < need; j++) {
                num += "0"
            }
            return num
        }
    }
}

/**
 * Base64字符串转File文件
 * @param {String} base64 Base64字符串(字符串包含Data URI scheme，例如：data:image/png;base64, )
 * @param {String} filename 文件名称
 */
function base64toFile(base64, filename) {
    let arr = base64.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = window.atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
        type: mime
    });
}

/**
 * @description base64转blob
 */
const base64ToBlob = (base64) => {
    var parts = base64.split(';base64,');
    var contentType = parts[0].split(':')[1];
    console.log('base64ToBlob', contentType);
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {
        type: contentType
    });
}

/**
 * @param { File } file
 * @description file转base64
 */
const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            let base64String = reader.result;
            resolve(base64String);
        }
        reader.onerror = error => {
            reject(error);
        }
        reader.readAsDataURL(file);
    })
}

// 验证文件
/**
 * @description 验证文件
 * @param {*} file 后端返回的二进制文件流
 * @return {*} true | Error
 */
let verifyFile = (file) => {
    return new Promise((resolve, reject) => {
        // console.log('file', file);
        if (file instanceof Blob && file.size == 0) {
            reject('文件为空');
        }
        let fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                let obj = JSON.parse(event.target.result);
                if (typeof obj === 'object') {
                    reject('文件不存在');
                }
            } catch (error) {
                // console.log('fileReader文件.catch', error);
                resolve(true);
            }
        }
        fileReader.readAsText(file);
    });
}

/** 
 * @param {String} fileName 文件名称
 * @param {Blob|undefined} file 后端返回的流blob
 * @param {{ type?: String }|undefined} options new Blob的options参数
 * @param {base64|undefined} url data64文件
 * @description 下载blob文件  在@/core/mixin中的downloadFile方法中使用(可使用this.downloadFile调用core/mixin中downloadFile)，如果为非electron客户端，则使用以下方法下载；反之，向ipcMain发送事件，让主进程去下载
 */
let downloadFile = async (fileName, file, options, url) => {
    let href;
    let blob;
    let base64Reg = /^data:image\/([a-z]+);base64/i;
    let blobReg = /^blob:https?:/;
    if (file instanceof Blob) {
        await verifyFile(file);
        blob = new Blob([file], options || {});
        href = window.URL.createObjectURL(blob);
    } else if (blobReg.test(url)) {
        href = url;
    } else if (base64Reg.test(url)) {
        blob = base64ToBlob(url);
        console.log('fileName', fileName);
        href = window.URL.createObjectURL(blob);
    }
    let link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    link.style.display = 'none';
    console.log('downloadFile', href);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
}

/** 下载url文件 */

function getFourVerifyWord() {
    let baseChar = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    // 0 - 35 对用上面基础字符
    let baseNumber = () => parseInt(Math.random() * 36)
    // 0 - 4 为true 5-9为false
    let baseBoolean = () => parseInt(Math.random() * 10) < 5
    let baseColor = () => `rgb(${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)})`
    let targetArray = []
    for (let i = 0; i < 4; i++) {
        var char = baseChar[baseNumber()]
        targetArray.push({
            word: typeof char === "string" && baseBoolean() ? char.toLocaleUpperCase() : char,
            color: baseColor(),
        })
    }
    console.log(targetArray)
    return targetArray
}

/** 
 * @param  { string|undefined } letter  字母
 * @description 是否是小写字母
 */
let isLowerLetter = (letter) => {
    if (typeof letter !== 'string') return false;
    let reg = /[a-z]/;
    return reg.test(letter);
}

/** 
 * @param  { string|Array } name  名称
 * @description 获取驼峰名称
 */
let getCamelCaseName = (name) => {
    if (typeof name === 'string') {
        name = name.split('');
    }
    let findIndex = name.indexOf('-');
    if (findIndex === -1) {
        // console.log(name);
        // console.log(name[0]);
        name[0] = name[0].toUpperCase();
        return name.join('');
    }
    if (isLowerLetter(name[findIndex + 1])) {
        name[findIndex + 1] = name[findIndex + 1].toUpperCase();
    } else {
        // console.log('!isLowerLetter', name, name[findIndex + 1], isLowerLetter(name[findIndex + 1]));
    }
    name.splice(findIndex, 1);
    return getCamelCaseName(name);
}

export {
    deepClone,
    getChineseNum,
    getUuid,
    toFixed,
    base64toFile,
    fileToBase64,
    downloadFile,
    getFourVerifyWord,
    getCamelCaseName,
}