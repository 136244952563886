import "@/core/view.js"
import { getSortedRoutesByGroup } from "./generate-routes"

/** 打印 */
const Print = () => import("@/views/Print");

/** 打印 */
const PrintDesign = () => import("@/views/PrintDesign");

import configRoutes from "./config/index"

/** layout固定路由表 */
const constantLayoutRoutes = [
    {
        path: "/",
        redirect: "/doc-structure",
        // name: "Home",
        // component: Home,
        // meta: {
        // 	title: "首页",
        // 	groupName: '工作台',
        // 	icon: "HomePage",
        // },
    },
    ...configRoutes,
    {
        path: "/print",
        name: "Print",
        component: Print,
        meta: {
            title: "自定义打印设计",
            groupName: "打印",
            icon: "HomePage",
        },
    },
    {
        path: "/print-design",
        name: "PrintDesign",
        component: PrintDesign,
        meta: {
            title: "打印设计",
            currentActiveMenu: '/print',
        },
    },
]

const routes = []

const addRoute = route => {}

const menus = getSortedRoutesByGroup(
    constantLayoutRoutes.filter(item => {
        return typeof item.component === "undefined" || !(typeof item.meta === "object" && item.meta && typeof item.meta.hideMenu === "boolean" && item.meta.hideMenu)
    })
)
// console.log('menus', menus);

export { constantLayoutRoutes, routes, menus, addRoute }
