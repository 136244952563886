<template>
	<div class="print-draggle-card">
		<div class="print-draggle-card--item ep-draggable-item" v-for="(item, index) in items" :key="index" :tid="item.tid">
			<component :is="item.svg"></component>
			<span>{{ item.text }}</span>
		</div>
		<el-upload v-show="false" v-bind="UploadConfig">
			<span ref="uploadTextRef">上传</span>
		</el-upload>
	</div>
</template>

<script>
import iconMixin from './assets/icon.mixin';
import { fileToBase64 } from '../../../../utils/utils';
export default {
	name: 'DraggleCard',
	mixins: [iconMixin],
	data(){
		return {
			items: [
				{
					text: '文本', svg: 'TextIcon', tid: 'defaultModule.text',
				},
				{
					text: '图片', svg: 'ImageIcon', tid: 'defaultModule.image',
				},
				// {
				// 	text: '长文', svg: 'TextLong', tid: 'defaultModule.longText',
				// },
				{
					text: '表格', svg: 'TableIcon', tid: 'defaultModule.table',
				},
				// {
				// 	text: '空白表格', svg: 'TableIcon', tid: 'defaultModule.emptyTable',
				// },
				// {
				// 	text: 'html', svg: 'H', tid: 'defaultModule.html',
				// },
				// {
				// 	text: '自定义', svg: 'TextIcon', tid: 'defaultModule.customText',
				// },
				{
					text: '横线', svg: 'HLine', tid: 'defaultModule.hline',
				},
				{
					text: '竖线', svg: 'VLine', tid: 'defaultModule.vline',
				},
				// {
				// 	text: '矩形', svg: 'RectIcon', tid: 'defaultModule.rect',
				// },
				// {
				// 	text: '椭圆', svg: 'EllipseIcon', tid: 'defaultModule.oval',
				// },
				{
					text: '条形码', svg: 'BarCode', tid: 'defaultModule.barcode',
				},
				{
					text: '二维码', svg: 'QrCode', tid: 'defaultModule.qrcode',
				},
			],
			accept: '.png, .jpg, .gif, .webp',
			cb: null,
		}
	},
	computed: {
		UploadConfig(){
			return {
				multiple: false,
				limit: 1,
				accept: this.accept,
				action: '#',
				/**
				 * @param { { name: string } } file
				*/
				beforeUpload: (file) => {
					let acceptArr = this.accept.split(',').map(item => {
						return item.replace(/^\s+/g, '').replace(/\s+$/g, '');
					}).filter(item => !!item);
					let acceptReg = new RegExp(`${ acceptArr.map(item => '\\' + item).join('|') }$`);
					// console.log('beforeUpload', file, acceptReg, acceptReg.test(file.name));
					if (!acceptReg.test(file.name)){
						this.$message({
							type: 'warning',
							message: `请上传${ acceptArr.join('、') }图片`
						});
						return false;
					}
				},
				httpRequest: (params) => {
					console.log('httpRequest', params);
					// let reader = FileRe
					fileToBase64(params.file)
					.then(base64String => {
						typeof this.cb === 'function' && this.cb(base64String);
					}).catch(error => {
						console.log('error', error);
					})
				}
			}
		}
	},
	methods: {
		openUpload(cb){
			// console.log('openUpload', this.$refs.uploadTextRef);
			this.$refs.uploadTextRef.click();
			typeof cb === 'function' && (this.cb = cb);
		}
	}
}
</script>

<style lang="less">
	.print-draggle-card{
		padding: 40px 0;
		display: grid;
		grid-template-columns: auto auto;
		justify-content: space-evenly;
		row-gap: 50px;
		white-space: nowrap;
		&>.print-draggle-card--item{
			width: 30px;
			color: #1890ff;
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			row-gap: 6px;
			cursor: move;
			&>svg{
				width: 30px;
				height: 30px;
				& path{
					fill: #1890ff;
				}
			}
		}
	}
</style>