<template>
	<div class="hiprint-design__wrapper">
		<div id="hiprint-design"></div>
	</div>
</template>

<script>
import iconMixin from './assets/icon.mixin';
export default {
	name: 'HiprintDesign',
	mixins: [iconMixin],
	data(){
		return {
		}
	}
}
</script>

<style lang="less">
	.hiprint-design__wrapper{
		padding: 24px;
	}
</style>