<template>
    <BasicDialog v-bind="dialogConfig" @confirm="handleConfirm" @close="handleClose">
        <div id="preview_content_design"></div>
        <BasicForm v-bind="formConfig" ref="FormRef" />
    </BasicDialog>
</template>
<script>
import { useDialog, useForm } from "@/core"
import { getCurrentClient, getCurrentClientAndPrinter, getHiprintTemplatePrintElements } from "@/modules/hiprint";
import '@/modules/hiprint/type';
export default {
    name: "PreviewDialog",
    data() {
        return {
            visible: false,
            waitShowPrinter: false,
            // 纸张宽 mm
            width: 0,
            // 模板
            hiprintTemplate: {},
            // 数据
            printData: {},

            currentPrinter: {},

            schemas: [],
        }
    },
    computed: {
        dialogConfig() {
            return useDialog({
                visible: this.visible,
                class: 'print-preview-dialog',
                // appendToBody: true,
                title: "预览",
                width: "50%",
                confirmButtonText: "打印",
            })
        },
        /**
         * @return { printer } 
        */
        getCurrentPrinter() {
            return this.currentPrinter;
        },
        formConfig() {
            return useForm({
                labelPosition: 'right',
                labelWidth: '100',
                colProps: {
                    span: 20,
                },
                schemas: [
                    ...this.schemas,
                    {
                        label: '份数',
                        prop: 'printNumber',
                        component: 'InputNumber',
                        rules: [
                            {
                                required: true,
                                message: '请输入打印份数'
                            }
                        ],
                        componentProps: {
                            min: 1,
                            precision: 0
                        }
                    }
                ],
            })
        }
    },
    watch: {
        formConfig: {
            handler(val) {
                console.log('formConfig', val);

            },
            immediate: false
        }
    },
    methods: {
        /**
         * @param {hiprintTemplate} hiprintTemplate 
         * @param {printer} currentPrinter
        */
        open(hiprintTemplate, printData, currentPrinter, width = "210") {
            console.log('open', hiprintTemplate.editingPanel);

            this.visible = true;
            this.width = hiprintTemplate.editingPanel ? hiprintTemplate.editingPanel.width : width;
            this.hiprintTemplate = hiprintTemplate;
            this.printData = printData;
            this.currentPrinter = currentPrinter;
            this.generateFormConfigByPrintElements(hiprintTemplate);
        },
        /**
         * @param {hiprintTemplate} hiprintTemplate 
         * @description 生成表单配置项 填充模拟数据
        */
        generateFormConfigByPrintElements(hiprintTemplate) {
            let printElements = getHiprintTemplatePrintElements(hiprintTemplate);
            let schemas = [];
            let model = {};
            for (let i = 0; i < printElements.length; i++) {
                let { title, field, } = printElements[i].options;
                let { type } = printElements[i].printElementType;
                // console.log(printElements[i].options.title);
                if (field) {
                    let defaultValue = type === 'barcode' ? '' : title;
                    !model[field] && (model[field] = defaultValue);
                    schemas.push({
                        label: title,
                        prop: field,
                        component: 'Input',
                        rules: [
                            {
                                required: type !== 'barcode',
                                message: `请输入${title}数据`
                            }
                        ],
                        defaultValue,
                        componentProps: {
                            placeholder: `请输入${title}数据`,
                            onBlur: ({ model }) => {
                                console.log('onBlur');
                                
                                $("#preview_content_design").html(this.hiprintTemplate.getHtml(model));
                            }
                        }
                    });
                }
            }
            this.$nextTick(() => {
                console.log('model', model, this.schemas);
                
                $("#preview_content_design").html(hiprintTemplate.getHtml(model))
            });
            this.schemas = schemas;
        },
        
        async handleConfirm() {
            if (!this.getCurrentPrinter) return;
            let { name, server: client } = this.getCurrentPrinter;
            if (!client || !name) {
                console.log(this.getCurrentPrinter);
                return;
            };
            try {
                let { valid, model } = await this.$refs.FormRef.validate();
                let { printNumber } = model;
                for (let i = 0; i < printNumber; i++){
                    let data = { ...model };
                    for (let key in data){
                        data[key] = data[key] + '-' + (i + 1)
                    }
                    this.hiprintTemplate.print2(
                        // this.printData,
                        data,
                        {
                            client: client.clientId,
                            printer: name,
                            title: '打印测试'
                        },
                        (...params) => {
                            console.log('params', ...params);
                        }
                    )
                }
            } catch (error) {
                console.log('error', error);
            }

            return
            this.hiprintTemplate.print2(
                // this.printData,
                {
                    sampleName: '样品名称',
                    sampleModel: '样品型号',
                    productNo: '12345465',
                },
                {
                    client: client.clientId,
                    printer: name,
                    title: '标签打印'
                },
                (...params) => {
                    console.log('params', ...params);

                }
            )
        },
        handleClose() {
            this.visible = false
        },
    },
}
</script>

<style lang="less">
@import url('./index.less');
</style>
