import { hiprint, defaultElementTypeProvider } from './hiprint.bundle.js'
// 调用浏览器打印js
import "./plugins/jquery.hiwprint.js";
// 默认配置
import "./hiprint.config";
// 样式
import "./css/hiprint.css"
import "./css/print-lock.css"

import './type.js';

// console.log('hiprint.hinnn', hinnn.event.);

/**
 * 自动连接 / 连接
 * cb: 连接回调， (status, msg) {
 *   // status: true/false
 *   // msg: status == true 时 返回socket.connect回调 e
 * }
 */
let autoConnect = function (cb) {
	console.log('autoConnect');
	window.autoConnect = true;
	window.hiwebSocket && window.hiwebSocket.hasIo() && window.hiwebSocket.start(cb);
};

/**
 * 取消自动连接 / 断开连接
 */
let disAutoConnect = function () {
	console.log('disAutoConnect');
	window.autoConnect = false;
	window.hiwebSocket && window.hiwebSocket.hasIo() && window.hiwebSocket.stop();
};
disAutoConnect();

let hiPrintPlugin = {
	disAutoConnect,
	install: function (Vue, name = '$hiPrint', autoConnect = true) {
		if (!autoConnect) {
			disAutoConnect();
		}
		let globalVue = Vue.prototype || Vue.config.globalProperties;
		globalVue[name] = hiprint;
		/**
	   * 预览打印，调起系统打印预览
		 * provider 左侧拖拽元素
		 * template 模版json字符串
		 * args 打印数据data, options,
		 */
		globalVue.$print = function (provider = defaultElementTypeProvider, template, ...args) {
			hiprint.init({
				providers: [new provider()]
			});
			var hiprintTemplate = new hiprint.PrintTemplate({
				template: template,
			});
			hiprintTemplate.print(...args);
			return hiprintTemplate;
		}
		/**
	   * 单模版直接打印， 需客户端支持
		 * provider 左侧拖拽项对象
		 * template 模版json字符串
		 * args 打印数据data, options,
		 */
		globalVue.$print2 = function (provider = defaultElementTypeProvider, template, ...args) {
			hiprint.init({
				providers: [new provider()]
			});
			var hiprintTemplate = new hiprint.PrintTemplate({
				template: template,
			});
			hiprintTemplate.print2(...args);
			return hiprintTemplate;
		}
	}
}

// const host = 'https://118.178.184.218:17521';
const host = 'https://power.labbol.com:17521'
// const host = 'https://www.printjs.cn:17521'
// const host = 'https://printjs.cn:17521'
// const host = 'https://v4.printjs.cn:17521'
const token = 'vue-plugin-hiprint';
// const token = 'hiprint*';

const printerName = 'ZDesigner ZT210-300dpi ZPL';

/**
 * @returns { Record<string, clientInfo> }
*/
const getClients = () => {
	return window.hiwebSocket.clients;
}

/**
 * @returns { { client: clientInfo, printer: printer } }
*/
const getCurrentClientAndPrinter = () => {
	let clients = getClients();
	let clientIds = Object.keys(clients);
	let currentClient;
	let currentPrinter;
	// console.log('getCurrentClientAndPrinter', clients, clientIds);
	
	for(let i = 0; i < clientIds.length; i++){
		let clientInfo = clients[clientIds[i]];
		let printerList = clientInfo.printerList;
		// console.log(clientInfo, printerList);
		if (!(Array.isArray(printerList) && printerList.length)) continue;
		let printer = printerList.find(printer => printer.name === printerName);
		if (printer){
			currentClient = clientInfo;
			currentPrinter = printer;
			break;
		}
	}
	return { client: currentClient, printer: currentPrinter };
}


/**
 * @description 获取所有客户端的打印机列表
 * @returns { ( printer | { server: clientInfo } )[] }
*/
const getAllPrinterList = () => {
	return window.hiwebSocket ? window.hiwebSocket.printerList || [] : [];
}

/**
 * @description 获取所有客户端的打印机列表
 * @returns { ( printer | { server: clientInfo } )[] }
*/
const getAllDefaultPrinterList = () => {
	return getAllPrinterList().filter(item => typeof item.isDefault === 'boolean' && item.isDefault);
}

/**
 * @param {hiprintTemplate}  hiprintTemplate 打印模板  通过new hiprint.PrintTemplate构造的实例
 * @description 获取打印模板的元素列表
 * @returns {printElements}
*/
const getHiprintTemplatePrintElements = (hiprintTemplate) => {
	return hiprintTemplate.editingPanel.printElements;
}

// hiprint.version = version

window.hiprint = hiprint;
export {
	autoConnect,
	disAutoConnect,
	hiprint,
	hiPrintPlugin,
	defaultElementTypeProvider,
	host,
	token,
	printerName,
	getCurrentClientAndPrinter,
	getAllPrinterList,
	getAllDefaultPrinterList,
	getHiprintTemplatePrintElements,
}


