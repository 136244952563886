<template>
	<div id="hiprint-setting" class="hiprint-setting">
		
	</div>
</template>

<script>
import iconMixin from './assets/icon.mixin';
export default {
	name: 'HiprintSetting',
	mixins: [iconMixin],
	data(){
		return {
		}
	}
}
</script>

<style lang="less">
	.hiprint-setting{

	}
</style>