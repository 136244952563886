import Vue from "vue"
import VueRouter from "vue-router";
import store from "@/store";
import { isElectron, ipcRenderer, isDev } from "@/core/Electron";
import {
    setLocalToken,
    getLocalToken,
    removeLocalToken,
} from '@/core/token';
Vue.use(VueRouter)
//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush = VueRouter.prototype.push

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果成功 调用原来的push方法
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        )
    }
}

import { constantLayoutRoutes } from './config';
import {
    LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
} from '@/core';

// /** 登录 */
// const Login = () => import("@/views/Login");

// /** 注册 */
// const Register = () => import("@/views/Register");

// /** 修改密码地址 */
// const ChangePasswordPath = '/ChangePassword';


/** 首页路由地址 */
const HomePath = constantLayoutRoutes[0].path;

/** 布局路由组件 */
const Layout = () => import("@/components/Layout");

const routes = [
    // 后台登录
    {
        path: LoginPath,
        name: LoginName,
        component: Login,
        meta: {
            title: "登录",
        },
    },
    {
        path: RegisterPath,
        name: RegisterName,
        component: Register,
        meta: {
            title: "注册",
        },
    },
    // 修改密码
    {
        path: ChangePasswordPath,
        name: ChangePasswordName,
        component: ChangePassword,
    },
    {
        path: "/",
        name: "Layout",
        redirect: HomePath,
        component: Layout,
        // beforeEnter(to, from, next){
        //     console.log('LandingPage.beforeEnter');
        //     // store.commit('setDocumentFrontTitle');
        //     next();
        // },
        children: constantLayoutRoutes,
    },
    {
        path: '*',
        redirect: '/',
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

//判断用户是否登录
/** 是否为初次加载当前token对应用户信息 */
let firstLoad = true;
// 当前环境为electron 本地token存在 且为生产模式 清除token
if (isElectron && getLocalToken() && !isDev){
    removeLocalToken();
}
// setLocalToken('1212');
// 路由守卫
router.beforeEach(async (to, from, next) => {
    /** query传递的token值 多用于系统或门户之间跳转 */
    let queryToken = to.query.token;
    // query传递的token值存在 设置token至本地
    if (queryToken) {
        setLocalToken(queryToken);
    }
    // 当前环境为electron 本地token存在 且为初次加载 直接设置当前窗口token
    else if (isElectron && getLocalToken() && firstLoad && isDev){
        setLocalToken(getLocalToken());
    }
    
    let loginPaths = [LoginPath, RegisterPath, ChangePasswordPath]
    let token = getLocalToken();
    console.log('beforeEach', token, firstLoad, to.path, from.path);
    /** 需要重定向的路由地址 */
    let redirect;
    // 当前窗口token存在
    if (token) {
        if (firstLoad) {
            await Promise.all([
                store.dispatch('getUserInfo'),
            ]);
            firstLoad = false;
        }
        //去往登录/注册页 拦截并发送登录请求
        if (['/', ...loginPaths].includes(to.path)) {
            let redirect = HomePath;
            if (from.query.redirect){
                redirect = decodeURIComponent(decodeURIComponent(from.query.redirect));
                if (redirect === to.path){
                    redirect = HomePath;
                }
            }
            console.log('beforeEach.token-if-2', to.path, loginPaths, redirect);
            next({
                path: redirect,
                replace: true
            })
        }
        else if (to.path === HomePath && from.query.redirect && decodeURIComponent(decodeURIComponent(from.query.redirect)) !== HomePath){
            console.log('beforeEach.token-else-if-1', to.path, loginPaths, decodeURIComponent(decodeURIComponent(from.query.redirect)));
            next({
                path: decodeURIComponent(decodeURIComponent(from.query.redirect)),
            })
        }
        else {
            console.log('beforeEach.token-else', to.path, );
            // console.log('token-existed, non-redirect', token, to.path, from.path);
            next()
            if (isElectron && loginPaths.includes(from.path)){
                ipcRenderer.send('setMainWindow');
            }
        }
    }
    // 当前窗口token不存在
    else {
        if (loginPaths.includes(to.path)) {
            firstLoad = true;
            next()
            console.log('else, ', to.path, from.path);
            // && !(loginPaths.includes(from.path) && loginPaths.includes(to.path))
            if (isElectron){
                ipcRenderer.send('setLoginWindow');
            } 
        }
        else {
            console.log('!token-else, ', to.path, from.path);
            next({
                path: LoginPath,
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }

})

export default router
// export const routerMenu = routes[0].children[0].children.filter(item => item.meta.showInSideMenu)
export {
    routes,
    // BusinessDataRoutes,
    HomePath,
    // OfflinePath,
    ChangePasswordPath,
}