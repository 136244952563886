<template>
    <BasicDialog v-bind="dialogConfig" @confirm="handleConfirm" @close="handleClose">
        <ul>
            <li>
                <span>1.确认安装配套打印客户端的电脑上已通过USB或网络等其他方式连接至打印机，并设置该打印机为默认打印机。</span>
            </li>
            <li>
                <span>2.安装打印客户端后，请在系统托盘中右键-设置中转服务地址。</span>
            </li>
            <li>
                <p>服务器地址: https://power.labbol.com:17521</p>
                
                <p>Token: vue-plugin-hiprint</p>
            </li>
        </ul>
        <template slot="footer">
            <el-button type="primary" @click="handleDownload">已知晓、确认下载</el-button>
        </template>
    </BasicDialog>
</template>
<script>
import { useDialog } from "@/core";
export default {
    name: "NoticeDialog",
    data() {
        return {
            visible: false,
        }
    },
    mounted(){
        this.init();
    },
    computed: {
        dialogConfig() {
            return useDialog({
                class: 'print-notice-dialog',
                visible: this.visible,
                // appendToBody: false,
                title: "注意事项",
                width: "800px",
            })
        },
    },
    methods: {
        init(){
            // this.open();
            // if (!sessionStorage.getItem('init-notice')){
            //     this.open();
            //     sessionStorage.setItem('init-notice', Date.now())
            // }
        },
        open() {
            this.visible = true;
        },
        handleConfirm() {
        },
        handleClose() {
            this.visible = false
        },
        handleDownload(){
            this.api.getListAPIPrint(
                '',
                'downloadPrintClient'
            ).then(res => {
                // console.log(res.data.data);
                window.open(res.data.data, '_blank')
                this.handleClose();
            }).catch(error => {
                console.log('error', error);
            })
        }
    },
}
</script>

<style lang="less">
@import url('./index.less');
</style>
