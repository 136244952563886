import Index from './index.vue';
export default Index;

import Printer from './assets/icon/Printer.svg';
// console.log('Printer', Printer);

let install = {
    install(Vue){
        Vue.component('Printer', Printer);
    }
}
export {
    install,
}