// 打开打印弹窗
import VueComponent from './index.vue';
/** 打开打印弹窗 */
let openPrint = () => {};
openPrint.show = () => {};
openPrint.hide = () => {};
export default {
    install(Vue) {
        const $app = Vue.extend(VueComponent);
        const VueInstance = new $app().$mount(document.createElement('div'));
        // console.log('VueInstance', VueInstance);

        document.body.appendChild(VueInstance.$el);
        let show = (params) => {
            if (typeof params !== 'object' || !params) {
                params = {};
            }
            // console.log('show', params, VueInstance);
            Object.keys(params).forEach((key) => {
                // console.log('key', key, VueInstance[key]);

                VueInstance[key] = params[key];
            });
            VueInstance.visible = true;
        };
        openPrint = show;
        openPrint.show = show;
        let hide = () => {
            VueInstance.visible = false;
        };
        openPrint.hide = hide;
        Vue.prototype.$openPrint = openPrint;
    },
};

export {
    /** 打开打印弹窗 */
    openPrint
};
