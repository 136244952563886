<template>
    <BasicDialog v-bind="dialogConfig" @confirm="handleConfirm" @close="handleClose">
        <template slot="printer-append">
            <el-button :loading="printerListLoading" @click="handleRefresh">刷新</el-button>
        </template>
        <template slot="printerState">
            <div v-bind="printerStateProps">
                <template v-if="isPrinterConnected">
                    打印中转连接服务正常，请选择默认打印机进行打印
                </template>
                <template v-else-if="visible">
                    打印中转服务连接超时，打印功能暂时无法使用，请<span @click="reconnect">重连</span>或联系客服
                </template>
            </div>
        </template>
        <template slot="preview">
            <div :id="previewContentId"></div>
        </template>
    </BasicDialog>
</template>

<script>
import { useDialog } from '@/core';
import { getAllDefaultPrinterList, hiprint, host, token } from '../../modules/hiprint';
import { getUuid } from '@/utils/utils';
import '../../modules/hiprint/type';
export default {
    name: 'OpenPrint',
    data() {
        return {
            visible: false,
            title: '打印',

            /** 中转连接状态 */
            isConnected: null,
            /** 正在建立连接 */
            connectLoading: true,
            /** 打印机列表 */
            printerList: [],
            /** 打印机列表正在刷新 */
            printerListLoading: false,

            /** 打印设计列表 */
            printDesignList: [],
            /** 打印设计详情 */
            printDesignData: {},

            /** 预览节点id */
            previewContentId: 'preview_content_' + getUuid(32, 36),

            hiprintTemplate: null,
        }
    },
    computed: {
        dialogConfig() {
            return useDialog({
                class: 'open-print-dialog',
                title: '打印',
                visible: this.visible,
                width: '900px',
                formConfig: {
                    labelPosition: 'right',
                    labelWidth: '90',
                    colProps: {
                        span: 12,
                    },
                    schemas: [
                        {
                            label: '状态',
                            prop: 'printerState',
                            colProps: {
                                span: 20
                            },
                        },
                        {
                            label: '打印机',
                            prop: 'printer',
                            component: 'Select',
                            rules: [
                                { required: true, message: '请选择默认打印机' }
                            ],
                            componentProps: {
                                placeholder: '请选择默认打印机',
                                options: this.printerList,
                            }
                        },

                        {
                            label: '份数',
                            prop: 'printerNum',
                            component: 'InputNumber',
                            rules: [
                                { required: true, message: '请输入打印份数' }
                            ],
                            contentTip: '每条数据项打印的次数',
                            componentProps: {
                                controlsPosition: 'right',
                                precision: 0,
                                min: 1,
                                
                            }
                        },
                        {
                            label: '模板',
                            prop: 'printDesignId',
                            component: 'Select',
                            rules: [
                                { required: true, message: '请选择打印模板' }
                            ],
                            componentProps: {
                                placeholder: '请选择打印模板',
                                options: this.printDesignList,
                                onChange: ({ value }) => {
                                    this.handlePrintDesignChange(value);
                                }
                            }
                        },
                        {
                            label: '预览',
                            prop: 'preview',
                            colProps: {
                                span: 24
                            }
                        }
                    ]
                }
            });
        },
        /** 打印设计类型 */
        printDesignTypeOptions() {
            return [
                { label: "样品", value: "01" },
                { label: "设备", value: "02" },
            ]
        },
        /** 打印机中转连接装备 */
        isPrinterConnected() {
            return this.isConnected === true && !this.connectLoading;
        },
        printerStateProps() {
            return {
                style: {
                    color: this.isPrinterConnected ? 'green' : 'red'
                }

            }
        }

    },
    watch: {
        visible: {
            handler(val) {
                if (val) {
                    this.init();
                    this.getPrintDesignList();
                } else {
                    this.initClose();
                    // this.$nextTick(() => {
                    //     this.initClose();
                    // })
                    
                }
            },
            immediate: false,
        }
    },
    methods: {
        init() {
            hiprint.refreshPrinterList(list => {
                // console.log('refreshPrinterList', list);
                if (this.printerListLoading) {
                    this.$message({
                        type: 'success',
                        message: '刷新成功'
                    });
                }
                this.printerListLoading = false;
                this.printerList = getAllDefaultPrinterList().map(item => {
                    return {
                        // ...item,
                        label: item.name,
                        value: item
                    }
                })
            })
            this.startConnect();
        },
        /**
         * @description 开始连接
        */
        startConnect() {
            this.connectLoading = true;
            hiprint.hiwebSocket.setHost(
                host,
                token,
                isConnected => {
                    console.log('start', isConnected);
                    this.isConnected = isConnected;
                    this.connectLoading = false;
                }
            )
        },
        initClose() {
            this.isConnected = null;
            this.stopConnect();
        },
        stopConnect() {
            hiprint.hiwebSocket.stop();
        },
        reconnect() {
            this.stopConnect();
            this.startConnect();
        },
        handleRefresh() {
            this.printerListLoading = true;
            hiprint.hiwebSocket.refreshPrinterList();
        },
        getPrintDesignList() {
            this.api.postFormAPIPrint(
                {
                    pageNum: 1,
                    pageSize: 10000,
                    printDesignName: '',
                    printDesignType: '',
                },
                "queryPrintDesign"
            )
                .then(res => {
                    this.printDesignList = (res.data.root || []).map(item => {
                        let designType = this.printDesignTypeOptions.find(Item => Item.value === item.printDesignType);
                        return {
                            label: item.printDesignName + (designType ? ('/' + designType.label) : ''),
                            value: item.printDesignId
                        }
                    })
                    // console.log('printDesignList', this.printDesignList);
                })
                .catch(error => {
                    console.log(error, "error")
                })
        },
        /**
         * @param {string} printDesignId 打印设计id
         * @description 打印设计下拉change
        */
        async handlePrintDesignChange(printDesignId) {
            try {
                await this.getPrintDesign(printDesignId);
                this.hiprintTemplate = new hiprint.PrintTemplate({
                    // template: panel,
                    template: this.printDesignData,
                });
                // console.log('hiprintTemplate', hiprintTemplate);
                $(`#${ this.previewContentId }`).html(this.hiprintTemplate.getHtml({}))
                
            } catch (error) {
                console.log('handlePrintDesignChange.catch', error);
            }
        },
        /**
         * @param {string} printDesignId 打印设计id
         * @description 获取打印设计信息
        */
        getPrintDesign(printDesignId) {
            return new Promise((resolve, reject) => {
                this.api.getListAPIPrint(
                    {
                        printDesignId,
                    },
                    'getPrintDesign'
                ).then(res => {
                    let { printDesignData } = res.data.data;
                    this.printDesignData = printDesignData;
                    resolve(printDesignData || {});
                }).catch(error => {
                    console.log('getPrintDesign.error', error);
                    reject(error);
                })
            })
        },
        /**
         * @param { { printer: printer } } model 
        */
        handleConfirm(model){
            console.log('handleConfirm', model);
            let { printer } = model;
            let { server, name } = printer;
            if (this.hiprintTemplate){
                this.hiprintTemplate.print2(
                        // this.printData,
                        {  },
                        {
                            client: server.clientId,
                            printer: name,
                            title: '打印'
                        },
                        (...params) => {
                            console.log('params', ...params);
                        }
                    )
            }
        },
        handleClose() {
            this.visible = false;
        }
    }
}
</script>

<style lang="less">
@import url('./index.less');
</style>