// import http from '../utils/http'
import http from './http';
import { ssoServer } from './global';
/** 开发环境 */
let isDev = process.env.NODE_ENV === "development";

// let base = isDev ? 'http://192.168.23.11:13017' : ssoServer;
// let base = isDev ? 'http://47.104.68.37:13012' : ssoServer;
let base = '';
// let base = isDev ? 'http://47.104.68.37:13017' : ssoServer;
/** 产品  不需要登录  `http://47.104.68.37:13017/unifyauth/sso/` */
// let product =  `${ base }/unifyauth/product/`;
let product =  '/unifyauth/product/';
// let product =  isDev ? 'http://47.104.68.37:13017/unifyauth/product/' : '';

/** 登录认证服务  统一使用阿里云服务  `http://47.104.68.37:13017/unifyauth/sso/` */
// let sso =  isDev ? 'http://192.168.23.27:50000/unifyauth/sso/' : '';
// let sso = `${ base }/unifyauth/sso/`;
let sso =  '/unifyauth/sso/';
/** 基础服务  统一使用阿里云服务  `http://47.104.68.37:13017/unifyauth/tenant/` */
// let tenant =  `${ base }/unifyauth/tenant/`;
let tenant =  '/unifyauth/tenant/';
// let tenant =  isDev ? 'http://192.168.23.27:50000/unifyauth/tenant/' : '';

// let group =  `${ base }/unifyauth/group/`;
let group =  '/unifyauth/group/';

/** 成员  /unifyauth/member/  */
// let member =  `${ base }/unifyauth/member/`;
let member =  `/unifyauth/member/`;

/** 角色  /unifyauth/role/  */
let role =  `${ base }/unifyauth/role/`;


/** `/v1.0/resource/` */
let resource = base + '/v1.0/resource/';

/** 模板管理（通用、项目、其他、文档结构）、数据集 */
let template = base + '/v1.0/resource/';

/** 自定义表单 */
let customForm = base + '/v1.0/power/customform/form/';

/** 打印 */
let print = base + '/v1.0/power/print/';
// 13012

export default class api {

    //==========↓==========↓==========↓==========↓==========GET==========↓==========↓==========↓==========↓==========

    /**
     *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
     *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
     */
    static getListAPI(params, url, config) {
        return http.get(params, `${base}${url}`, config)
    }
    static getListAPIProduct(params, url, config) {
        return http.get(params, `${product}${url}`, config)
    }
    static getListAPISso(params, url, config) {
        return http.get(params, `${sso}${url}`, config)
    }
    static getListAPItenant(params, url, config) {
        return http.get(params, `${tenant}${url}`, config)
    }
    static getListAPIGroup(params, url, config) {
        return http.get(params, `${group}${url}`, config)
    }
    static getListAPIMember(params, url, config) {
        return http.get(params, `${member}${url}`, config)
    }
    static getListAPIRole(params, url, config) {
        return http.get(params, `${role}${url}`, config)
    }
    static getListAPIResource(params, url, config) {
        return http.get(params, `${resource}${url}`, config)
    }
    /** 自定义表单 */
    static getListAPICustomForm(params, url, config) {
        return http.get(params, `${customForm}${url}`, config)
    }
    /** 打印 */
    static getListAPIPrint(params, url, config) {
        return http.get(params, `${print}${url}`, config)
    }

    /** 模板管理（通用、项目、其他、文档结构）、数据集 */
    static getListAPITemplate(params, url, config) {
        return http.get(params, `${template}${url}`, config)
    }
  
    //==========↑==========↑==========↑==========↑==========GET==========↓==========↓==========↓==========↓==========

    //==========↓==========↓==========↓==========↓==========POST==========↓==========↓==========↓==========↓==========
    static postFormAPI(params, url, urlData, config) {
        return http.post(params, `${ base }${url}`, urlData, config)
    }
    static postFormAPIProduct(params, url, urlData, config) {
        return http.post(params, `${product}${url}`, urlData,  config)
    }
    static postFormAPISso(params, url, urlData, config) {
        return http.post(params, `${sso}${url}`, urlData,  config)
    }
    static postFormAPItenant(params, url, urlData, config) {
        return http.post(params, `${tenant }${url}`, urlData, config)
    }
    static postFormAPIGroup(params, url, urlData, config) {
        return http.post(params, `${group }${url}`, urlData, config)
    }
    static postFormAPIMember(params, url, urlData, config) {
        return http.post(params, `${member}${url}`, urlData, config)
    }
    static postFormAPIRole(params, url, urlData, config) {
        return http.post(params, `${role}${url}`, urlData, config)
    }

    static postFormAPIResource(params, url, urlData, config) {
        return http.post(params, `${resource}${url}`, urlData, config)
    }
    /** 自定义表单 */
    static postFormAPICustomForm(params, url, urlData, config) {
        return http.post(params, `${customForm }${url}`, urlData, config)
    }
    /** 打印 */
    static postFormAPIPrint(params, url, urlData, config) {
        return http.post(params, `${print }${url}`, urlData, config)
    }

    /** 模板管理（通用、项目、其他、文档结构）、数据集 */
    static postFormAPITemplate(params, url, urlData, config) {
        return http.post(params, `${template }${url}`, urlData, config)
    }

}

export {
    tenant,
}